(function($) {
	$(document).ready(function() {
		$('*[data-vimeo-modal-trigger]').each(function() {
			VimeoJS.initializeVideoInModalTrigger($(this));
		});
		$('*[data-vimeo-embed-trigger]').each(function () {
			VimeoJS.initializeEmbeddedVideoTrigger($(this));
		});
	});

	const VimeoJS = {
		initializeVideoInModalTrigger: function(trigger) {
			const that = this;
			this.attachVimeoPlayerScript();
			trigger.on('click', function (e) {
					e.preventDefault();
					that.openModal(trigger.data('vimeo-videoid'));
				});
		},
		
		initializeEmbeddedVideoTrigger: function(trigger) {
			const that = this;
			this.attachVimeoPlayerScript();
			trigger.on('click', function(event) {
				if (event) {
					event.preventDefault();
					that.startEmbeddedPlayer(trigger, trigger.data('vimeo-videoid'));
				}
			});
		},
		
		openModal: function(videoId) {
			const that = this;
			const modal = $('<div class="video-modal"></div>');
			const overlay = $('<div class="video-modal-overlay"></div>');
			const videoContainer = $('<div class="video-modal-player" id="video-modal-player"></div>');
			modal.append(overlay);
			modal.append(videoContainer);
			$('body').append(modal);

			const player = this.startPlayer(videoId, 'video-modal-player');

			overlay.on('click', function() {
				that.closeModal(player, modal);
			});
		},

		closeModal: function(player, modal) {
			player.unload();
			modal.remove();
		},

		attachVimeoPlayerScript: function() {
			const url = 'https://player.vimeo.com/api/player.js';
			const tag = $('<script src="'+url+'"></script>');
			if ($('script[src="'+url+'"]').length <= 0) {
				$('script').first().before(tag);
			}
		},

		startEmbeddedPlayer: function(trigger, videoId) {
			const containerId = this.replacePictureWithVideo(trigger);
			this.startPlayer(videoId, containerId);
		},

		replacePictureWithVideo: function(trigger) {
			const containerId = 'video-embed-player-' + parseInt(Math.random() * 1000000);
			const videoContainer = $('<div class="data-vimeo-id video-embed-player" id="' + containerId + '"></div>');
			$('.video-embed').addClass('video-loaded');
			trigger.replaceWith(videoContainer);
			return containerId;
		},

		startPlayer: function(videoId, containerId) {
			return new Vimeo.Player(containerId, {
				id: videoId,
				autoplay: true,
				responsive: true
			});
		},
	}
}(jQuery));
