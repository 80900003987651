/**
 * Youtube
 *
 * Usage:
 *
 * $('#my-video-container').youtube({});
 *
 * <div data-youtube-modal-trigger data-youtube-videoid="...">Open video</a>
 *
 */

(function ($) {

	var Youtube = {

		init: function () {
			// load the YouTube script asynchronously and only once
			if (!window.onYouTubeIframeAPIReady) {
				window.onYouTubeIframeAPIReady = function () {
					document.dispatchEvent(new CustomEvent('onYouTubeIframeAPIReady', {}))
				};
			}

			var url = 'https://www.youtube.com/iframe_api';
			var tag = $('<script src="' + url + '"></script>');
			if ($('script[src="' + url + '"]').length > 0) {
				// tag already exists, skip
			} else {
				// append the tag
				$('script').first().before(tag);
			}
		},

		onPlayerReady: function (event, onReadyCallback) {
			onReadyCallback(event)
		},

		initModalTrigger: function (trigger) {
			// create and append the modal
			var videoId = trigger.data('youtube-videoid');
			var id = 'video-embed-player-' + parseInt(Math.random() * 1000000);

			var $modal = $('<div class="video-modal" id="youtube-video-modal"></div>');
			var $overlay = $('<div class="video-modal-overlay" id="youtube-video-modal-overlay"></div>');
			var $videoContainer = $('<div class="video-modal-player" id="'+id+'"></div>');

			$modal.append($overlay);
			$modal.append($videoContainer);
			$modal.hide();
			$('body').append($modal);

			var onReadyCallback = function (event) {
				trigger.css('cursor', 'pointer');
				trigger.on('click', function () {
					$modal.show();
					event.target.playVideo();
				});

				// stop the player and close
				$overlay.on('click', function () {
					event.target.stopVideo();
					$modal.hide();
				});
			};

			// start the player
			this.loadPlayer(videoId, id, onReadyCallback);
		},

		initEmbedPlayer: function (trigger) {
			// replace the image with the player
			var videoId = trigger.data('youtube-videoid');
			var id = 'video-embed-player-' + parseInt(Math.random() * 1000000);

			var $videoContainer = $('<div class="video-embed-player" id="' + id + '"></div>');
			$videoContainer.hide();
			$videoContainer.insertAfter(trigger);

			var onReadyCallback = function (event) {
				trigger.css('cursor', 'pointer');
				trigger.on('click', function () {
					$('#' + id).show();
					event.target.playVideo();
				});
			};

			// load the player
			var player = this.loadPlayer(videoId, id, onReadyCallback)
		},

		loadPlayer: function (videoId, containerId, onReadyCallback) {
			var that = this;
			return new YT.Player(containerId, {
				height: '100%',
				width: '100%',
				videoId: videoId,
				events: {
					'onReady': function (event) {
						that.onPlayerReady(event, onReadyCallback);
					},
				}
			});
		},
	};

	Youtube.init();

	document.addEventListener('onYouTubeIframeAPIReady', function () {
		$('*[data-youtube-embed-trigger]').each(function () {
			$(this).show();
			Youtube.initEmbedPlayer($(this));
		});
		$('*[data-youtube-modal-trigger]').each(function () {
			$(this).show();
			Youtube.initModalTrigger($(this));
		});
	});

}(jQuery));
