import * as basicScroll from "basicscroll";

(function($) {
	$.fn.parallax = function () {
		return $(this).each(function () {
			const section = this;
			const _from = section.getAttribute("data-from") || '0px';
			const _to = section.getAttribute("data-to") || section.offsetHeight;
			const instance = basicScroll.create({
				elem: section,
				from: 'top-bottom',
				to: 'bottom-top',
				direct: true,
				props: {
					'--ty': {
						from: _from,
						to: _to,
					}
				}
			})
			instance.start();
		});
	}
}(jQuery));
