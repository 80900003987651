/**
 * Modal
**/

(function($) {

	const Modal = {
		openModal: function(trigger) {
			const that = this;
			const $trigger = $(trigger);
			const className = 'modal-image';
			const $content = $(that.getContent($trigger));
			const $modal = $('<div class="' + className + '"></div>');
			const $overlay = $('<div class="' + className + '-overlay"></div>');
			const $dialog = $('<div class="' + className + '-dialog"></div>');
			const $container = $('<div class="' + className + '-content"></div>');
			const $containerBody = $('<div class="' + className + '-content-body"></div>');
			const $closeBtn = $('<button type="button" class="' + className + '-close"><span aria-hidden="true">×</span></button>')

			$modal.append([$overlay, $dialog]);
			$dialog.append($container);
			$container.append($containerBody);
			$containerBody.append($content.addClass(className + '-content-item'));
			$('body').append($modal);

			$content.on('load', function() {
				$containerBody.append($closeBtn);
			})

			$modal.on('click', function(e) {
				if (!$(e.target).hasClass(className + '-content-item')) {
					that.closeModal($modal);
				}
			});
		},

		closeModal: function(modal) {
			modal.remove();
		},

		initModalTrigger: function(trigger) {
			const that = this;
			trigger.on('click', function (e) {
				if (e) {
					e.preventDefault();
				}
				that.openModal(trigger);
			});
		},

		getContent: function(trigger) {
			return '<img class="img-fluid modal-image-src" src="' + trigger.attr('src') + '" />';
		},
	};

	$(document).ready(function() {
		$('*[data-modal-image]').each(function() {
			Modal.initModalTrigger($(this));
		});
	});

}(jQuery));
