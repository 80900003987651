/**
 * Hubspot form dialog
**/

(function($) {

	var dialogId = 'hubspot-form-dialog';
	var formId = 'hubspot-form';

	const Dialog = {
		init: function(e) {
			if (e) {
				e.preventDefault();
			}
			var that = this;

			var $overlay = $('<div class="dialog-overlay" id="' + dialogId + '"></div>');
			var $overlayClose = $('<div class="dialog-close-overlay"></div>');
			var $container = $('<div class="dialog-container"></div>');
			var $containerBody = $('<div class="dialog-container-body" id="' + formId + '"></div>');
			var $dialogClose = $('<span class="dialog-close"><i class="icon icon-delete"></i></span>')

			$overlay.append($overlayClose);
			$container.append($containerBody);
			$overlay.append($container);
			$overlay.append($dialogClose);
			$overlay.hide();
			$('body').append($overlay);

			$('.dialog-close, .dialog-close-overlay').on('click', function() {
				that.close();
			});
		},
		open: function() {
				hbspt.forms.create({
					region: "na1",
					portalId: "6100072",
					formId: "3a81beeb-976a-4dc1-b744-3ab61b2ebcc1",
					target: `#hubspot-form`,
					onFormSubmit: function () {
						$(document).trigger("onHubspotFormSubmit");
					}
				});
			$("body").addClass("overflow-hidden");
			$('body').find('#'+ dialogId).show();
		},

		close: function() {
			$("body").removeClass("overflow-hidden");
			$('body').find('#'+ dialogId).hide();
		}
	};

	$(document).ready(function() {
		var $hubspotTrigger = $('*[data-hubspot-form-dialog]');
		if ( $hubspotTrigger.length) {
			Dialog.init();
			$($hubspotTrigger).on("click", function(){
				Dialog.open();
			});

			$(document).on( "onHubspotFormSubmit", function() {
				setTimeout(function () {
					Dialog.close();
				}, 1000);
			});
		}
	});
}(jQuery));
