import Swiper, { Navigation, Pagination } from 'swiper';

$(function () {
	const swiper = '*[data-swiper]';
	const $swiperContainer = $(swiper);
	const $swiperSlide = $('*[data-swiper-slide]');

	if ($swiperContainer.length) {
		const options = {
			slidesPerView: 1,
			speed: 600,
			centeredSlides: true,
			spaceBetween: 75,
			loop: false,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		};

		const isDisabled = $swiperContainer.find($swiperSlide).length === 1;
		if (isDisabled) {
			$.extend(options, {
				spaceBetween: 0,
				watchOverflow: true,
			});
		}
		Swiper.use([Navigation, Pagination]);
		const carousel = new Swiper(swiper, options);
	}
});
