;(function ($, window, document, undefined) {

	// the defaults
	var pluginName = 'catalog';
	var defaults = {
		maxChoices: null,  // the maximum amount of choices (if using checkboxLabels) allowed before other choices are disabled
	};

	// plugin constructor
	function Catalog(element, options) {
		this.options = $.extend({}, defaults, options);
		this.options.autocompleteLimit = this.options.autocompleteLimit ? this.options.autocompleteLimit : 5;
		this.options.highlightClass = this.options.highlightClass ? this.options.highlightClass : 'highlight';
		this.options.thumbnail = this.options.thumbnail ? this.options.thumbnail : false;
		this.options.showFilters = this.options.showFilters ? this.options.showFilters : false;

		this.container = $(element);
		this.elements = {};
		this.elements.wrapper = this.container.find('*[data-catalog-wrapper]');
		this.elements.autocomplete = this.options.autocomplete ? this.options.autocomplete : this.container.find('*[data-catalog-search-autocomplete]')

		// autocomplete
		this.elements.search = this.options.searchElement ? this.options.searchElement : this.container.find('*[data-catalog-search]');
		this.elements.searchIcon = this.options.searchIcon ? this.options.searchIcon : this.container.find('*[data-catalog-search-icon]');
		this.elements.searchClear = this.options.searchClear ? this.options.searchClear : this.container.find('*[data-catalog-search-clear]');

		// filter
		this.elements.toggle = this.container.find('*[data-catalog-toggle]');
		this.elements.dropdown = this.container.find('*[data-catalog-dropdown]');
		this.elements.searchFilter = this.container.find('*[data-catalog-search-filter]');
		this.elements.searchCheckbox = this.container.find('*[data-catalog-search-checkbox]');
		this.elements.searchEmpty = this.container.find('*[data-catalog-search-empty]');
		this.elements.searchNotEmpty = this.container.find('*[data-catalog-search-not-empty]');
		this.elements.itemsFilter = this.container.find('*[data-catalog-item-filter]');

		// search
		this.elements.resultTitle = this.container.find('*[data-search-result-title]');
		this.elements.filters = this.container.find('*[data-catalog-filter]');
		this.elements.categories = this.container.find('*[data-catalog-category]');

		this.elements.items = this.container.find('*[data-catalog-item]');
		this.elements.checkbox = this.container.find('*[data-catalog-item-checkbox]');
		this.elements.checkboxLabels = this.container.find('*[data-catalog-item-checkbox-label]');

		this.init();
	}

	function isNonEmptyString(val) {
		return (val !== null && val !== undefined && typeof val === 'string' && val.length);
	}

	var currentFocus = -1, // focus on autocomplete for nav between items using keys
		currentCategory,
		currentMatches,
		currentFilter;


	// plugin methods
	Catalog.prototype = {
		init: function () {
			var that = this;
			currentMatches = this.elements.items;

			that.applyUrlParamsFilters();

			// Keep Dropdown Open when clicked inside
			this.elements.toggle.on('click', function (e) {
				$('*[data-catalog-dropdown]').toggleClass('show');
				e.stopPropagation();
			});

			this.elements.dropdown.on('click', function(e) {
				e.stopPropagation();
			});

			$(document).on('click', function() {
				that.elements.dropdown.removeClass('show');
			});

			this.elements.searchCheckbox.on('click', function (e) {
				var el = e.target;
				var id = el.getAttribute('id');
				that.applyFilterTags(id);
				that.applyFilter('integration', true);
				that.handleMatches(that.elements.filters, 'catalog-filter', 'category');
				that.applyCategoryFilter.bind(that)(currentCategory);
				that.applyActiveIntegCategoryFilter();
			});

			this.container.on('click', '*[data-catalog-badge]', function (e) {
				var el = e.target;
				that.removeFilter(el);
				that.applyFilter('integration', true);
				that.handleMatches(that.elements.filters, 'catalog-filter', 'category');
				that.applyCategoryFilter.bind(that)(currentCategory);
				that.applyActiveIntegCategoryFilter();
			});

			this.elements.items.on('click', function (e) {
				var categoryParam = that.getSearchParams('category');
				var href = e.target.getAttribute("href");
				if(categoryParam) {
					e.preventDefault();
					window.location = href + "?category=" + categoryParam;
				}
			});

			this.elements.search.on('input', function (e) {
				var value = e.target.value;
				var self = $(this);
				currentFocus = -1;
				that.autocompleteClose();

				if (value.length > 0) {
					self.parent().find('*[data-catalog-search-clear]').show();
					that.applyAutocomplete(value);
					that.applyUrlParam('search', value);
				} else {
					that.elements.itemsFilter.show();
					that.elements.items.show();
					self.parent().find('*[data-catalog-search-clear]').hide();
				}
			});

			this.elements.searchFilter.on('input', function (e) {
				var value = e.target.value;
				var self = $(this);

				if (value.length > 0) {
					self.parent().find('*[data-catalog-search-clear]').show();
					that.applySearch(value, that.elements.itemsFilter);
				} else {
					that.elements.itemsFilter.show();
					self.parent().find('*[data-catalog-search-clear]').hide();
				}
			});

			this.elements.searchIcon.on('click', function () {
				var value = that.elements.search.val();

				if (value.length > 0) {
					that.removeFilter();
					that.autocompleteClose();
					that.applyUrlParam('search', value);
					that.applySearch(value, that.elements.items);
					that.applySearchResultTitle(value);
					that.handleMatches(that.elements.filters, 'catalog-filter', 'category');
					that.handleMatches(that.elements.searchCheckbox, 'catalog-item-filter', 'integration');
					that.scrollTo(that.elements.wrapper);
				}
			});

			this.elements.searchClear.on('click', function () {
				var self = $(this);
				var wrapper = $('*[data-catalog-search-wrapper]');
				that.autocompleteClose();
				self.closest(wrapper).find('*[data-catalog-search-input]').val('');
				self.closest(wrapper).find('*[data-catalog-search-clear]').hide();
				self.closest(wrapper).find('*[data-catalog-item]').show();
				self.closest(that.elements.wrapper).find(that.elements.itemsFilter).show();
				if (self.closest(wrapper).find('[data-catalog-search]').length > 0) {
					that.elements.resultTitle.hide();
					that.resetCategoryFilter();
					that.removeFilter();
					that.elements.filters.removeClass('disabled');
					that.elements.searchCheckbox.removeAttr('disabled');
					that.elements.searchCheckbox.prop('checked', false);
					currentCategory = '';
					that.applyUrlParam('search', '');
					that.applySearch('', that.elements.items);
				}
			});

			this.elements.search.on('keydown', function (e) {
				var value = e.target.value;
				var code = (e.keyCode || e.which);
				var $list = $('.autocomplete-items'),
					$item;

				if ($list) {
					$item = $list.find('.dropdown-item');
				}

				switch (code) {
					case 40:
						currentFocus++;
						that.addActive($item);
						break;
					case 38:
						currentFocus--;
						that.addActive($item);
						break;
					case 13:
						if (value.length > 0) {
							if (currentFocus === -1 || currentFocus === $item.length - 1) {
								that.removeFilter();
								that.resetCategoryFilter();
								that.autocompleteClose();
								that.applyUrlParam('search', value);
								that.applySearch(value, that.elements.items);
								that.applySearchResultTitle(value);
								that.searchResultApplyCategory.bind(that)(currentCategory);
								that.handleMatches(that.elements.filters, 'catalog-filter', 'category');
								that.handleMatches(that.elements.searchCheckbox, 'catalog-item-filter', 'integration');
								that.scrollTo(that.elements.wrapper);
							} else {
								$item[currentFocus].click();
							}
						}
						break;
					default:
						return;
				}

			});

			this.elements.filters.on('click', function (e) {
				// apply category filters
				e.preventDefault();
				that.elements.filters.removeClass('active');
				var trigger = $(this);
				var name = trigger.data('catalog-filter');
				currentCategory = name;
				trigger.addClass('active');
				that.applyUrlParam('category', name);
				that.applyCategoryFilter.bind(that)(name);
				that.handleCheckboxState.bind(that)(name);
				that.searchResultApplyCategory.bind(that)(currentCategory);
			});

			// init checkboxes
			that.checkForMaxChoices();
			this.elements.checkboxLabels.each(function (i, label) {
				label = $(label);
				var checkbox = label.find('input[type="checkbox"]');
				if (checkbox.prop('checked') === true) {
					label.addClass('active');
				} else {
					label.removeClass('active');
				}
			});

			this.elements.checkboxLabels.on('click', function (e) {
				// toggle the checkbox, add active class and check for maxChoices
				var trigger = $(this);
				var checkbox = trigger.find('input[type="checkbox"]');
				if (!checkbox.attr('disabled')) {
					checkbox.prop('checked', !checkbox.prop('checked'));
					if (checkbox.prop('checked') === true) {
						trigger.addClass('active');
					} else {
						trigger.removeClass('active');
					}
					that.checkForMaxChoices();
				}
			});
		},

		applyUrlParamsFilters: function () {
			var that = this;
			var searchParam = this.getSearchParams('search');
			var integParams = this.getSearchParams('integration');
			var catParam = this.getSearchParams('category');
			var wrapper = $('*[data-catalog-search-wrapper]');

			if (searchParam) {
				this.elements.search.val(searchParam);
				var input = this.elements.search.closest(wrapper).find('*[data-catalog-search-input]');
				if (input.val().length > 0) {
					input.closest(wrapper).find('*[data-catalog-search-clear]').show();
				}
				this.applySearch(searchParam, this.elements.items);
				this.handleMatches(this.elements.searchCheckbox, 'catalog-item-filter', 'integration');
				this.handleMatches(this.elements.filters, 'catalog-filter', 'category');
				this.applySearchResultTitle(searchParam);
			}

			if (integParams) {
				integParams.split(',').map(function (id) {
					$('#' + id).prop('checked', true);
					that.applyFilterTags(id);
				});
				this.applyFilter('integration');
				this.applyActiveIntegCategoryFilter();
			}

			if (catParam) {
				currentCategory = catParam;
				this.elements.filters.removeClass('active');
				this.applyCategoryFilter.bind(that)(catParam);
				this.handleCheckboxState.bind(that)(catParam);
				this.searchResultApplyCategory.bind(that)(catParam);
				this.elements.filters.filter('[data-catalog-filter*="' +catParam+ '"]').addClass('active');
			}
		},

		removeFilter: function (el) {
			if (el) {
				var id = $(el).attr('data-catalog-badge');
				$('*[data-catalog-badge="' + id + '"]').parent().remove();
				$('#' + id).prop("checked", false);
			} else {
				$('*[data-catalog-badge]').parent().remove();
				this.elements.searchCheckbox.prop("checked", false);
			}
		},

		applyFilterTags: function (id) {
			var value = $('#' + id).val();
			var $list = this.container.find('[data-catalog-wrapper]');

			if ($('#' + id).prop('checked')) {
				var $button = $('<span/>').addClass('badge badge-neutral badge-custom badge-lg mr-2 mb-1').text(value).appendTo($list);
				$('<i/>').addClass('icon icon-delete icon-circle ml-2 cursor-pointer').attr('data-catalog-badge', id).appendTo($button);
			} else {
				$('*[data-catalog-badge="' + id + '"]').parent().remove();
			}
		},

		handleFilter: function (elements, type) {
			elements = $(elements);
			currentFilter = $(elements);

			var filter = elements.map(function () {
				return '[data-filter-' + type + '*="' + this.value + '"]';
			}).get().join(',');
			filter = (filter.length > 0) ? filter : '*';

			return filter;
		},

		handleParams: function (elements) {
			elements = $(elements);

			var filter = elements.map(function () {
				return this.getAttribute('id');

			}).get().join(',');
			filter = (filter.length > 0) ? filter : '*';

			return filter;
		},

		handleMatches: function (elems, data, type) {
			var that = this;
			var matched = {};
			var elements;
			if (type == 'integration') {
				elements = elems;
			} else {
				elements = elems.slice(1); // Ignore 'All
			}

			// keep count of shown items for each category to disable/enable it
			$.each(elements, function (i, item) {
				item = $(item);
				var name = item.data(data);
				matched[name] = that.elements.items.filter(':visible').filter('[data-filter-' + type + '*="' + name + '"]').length;
				if (matched[name] === 0) {
					if ((type == 'integration')) {
						item.parent().hide();
					} else {
						item.addClass('disabled');
						item.attr('disabled', '');
					}
				} else {
					if ((type == 'integration')) {
						item.parent().show();
					} else {
						item.removeClass('disabled');
						item.removeAttr('disabled');
					}
				}
			});
		},

		searchMatchesHandler: function (matches) {
			if (matches.length === 0) {
				this.elements.searchEmpty.show();
				this.elements.searchNotEmpty.hide();
			} else {
				this.elements.searchEmpty.hide();
				this.elements.searchNotEmpty.show();
			}
		},

		applyFilter: function (type, add_params) {
			var searchParam = this.getSearchParams('search');
			var checked_items = $('*[data-catalog-search-checkbox]:checked');
			var items = this.elements.items.slice(),
				filtered = items.filter(this.handleFilter(checked_items, type));

			if (searchParam) {
				this.applySearch(searchParam, items);
				filtered = items.filter(':visible');
				filtered = filtered.filter(this.handleFilter(checked_items, type));
			}

			var categories = this.handleFilter(checked_items, type);
			var params = this.handleParams(checked_items);

			filtered = filtered.filter(categories);

			items.not(filtered).hide();
			filtered.show();

			// reset filter if none of checkboxes is checked
			if (checked_items.length === 0) {
				currentFilter = ''
			}

			if (add_params) {
				this.applyUrlParam(type, params);
			}

			currentMatches = filtered;

			this.searchMatchesHandler(filtered);

			return filtered;

		},

		checkForMaxChoices: function () {
			if (this.elements.checkboxLabels.find('input[type="checkbox"]:checked').length >= this.options.maxChoices) {
				this.elements.checkboxLabels.each(function (i, label) {
					label = $(label);
					var checkbox = label.find('input[type="checkbox"]');
					if (checkbox.prop('checked') === false) {
						label.addClass('disabled');
						checkbox.attr('disabled', 'disabled');
					}
				});
			} else {
				this.elements.checkboxLabels.removeClass('disabled');
				this.elements.checkboxLabels.find('input[type="checkbox"]').removeAttr('disabled');
			}
		},

		searchResultApplyCategory: function (categoryName) {
			if ($('.result-title').length == 0) {
				var $result = $('<span/>').addClass('result-title').appendTo(this.elements.resultTitle);
				$('<span/>').text(" in ").appendTo($result);
				$('<span/>').addClass('result-title-cat').css('font-weight', 700).appendTo($result);
			}
			if (categoryName) {
				// show active category name in search results
				$('.result-title-cat').text(categoryName);
			} else {
				// remove active category from search result
				$('.result-title').remove();
			}
		},

		applyActiveIntegCategoryFilter: function () {
			// Additional check for items with multiple categories
			var that = this;
			if (currentFilter) {
				that.elements.filters.slice(1).addClass('disabled');
				$.each(currentFilter, function (i, item) {
					that.elements.filters.filter('[data-catalog-filter*="' + $(item).data('catalog-integration-category') + '"]').removeClass('disabled');
				});
			}
		},

		applyCategoryFilter: function (categoryName) {
			var items = currentMatches.slice();
			if (categoryName) {
				// keep only matched items
				var filtered = items.filter('[data-filter-category*="' + categoryName + '"]');
				currentMatches.show();
				currentMatches.not(filtered).hide();
			} else {
				// show all
				currentMatches.show();
			}
		},

		resetCategoryFilter: function () {
			currentCategory = '';
			this.elements.filters.removeClass('active');
			this.elements.filters.first().addClass('active');
			this.applyUrlParam('category', '');
		},

		handleCheckboxState: function (categoryName) {
			var checkboxes = this.elements.searchCheckbox;
			var filtered = checkboxes.filter('[data-catalog-integration-category*="' + categoryName + '"]');
			this.handleMatches(this.elements.searchCheckbox, 'catalog-item-filter', 'integration');

			if (categoryName) {
				checkboxes.not(filtered).parent().hide();
			}
		},

		scrollTo: function (element) {
			$('html, body').animate({scrollTop: $(element).offset().top}, 700);
		},

		getSearchParams: function (param) {
			var search = window.location.search;
			var params = new URLSearchParams(search);

			return params.get(param);
		},

		applyUrlParam: function (key, value) {
			var baseUrl = location.pathname,
				urlQueryString = document.location.search,
				newParam = key + '=' + value,
				params = '?' + newParam;

			if (urlQueryString) {
				var updateRx = new RegExp('([\?&])' + key + '=[^&]*');
				var removeRx = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

				// Remove param if there is no value
				if (value === undefined || value === null || value === '' || value === '*') {
					params = urlQueryString.replace(removeRx, "$1");
					params = params.replace(/[&;]$/, "");

					// Update existing params
				} else if (urlQueryString.match(updateRx) !== null) {
					params = urlQueryString.replace(updateRx, "$1" + newParam);

					// if no query strings
				} else if (urlQueryString === '') {
					params = '?' + newParam;

				} else {
					params = urlQueryString + '&' + newParam;

				}
			}
			// remove question mark if there is no params
			params = params === '?' ? '' : params;

			window.history.replaceState({}, "", baseUrl + params);

		},

		applyAutocomplete: function (searchString) {
			var that = this;
			var matches = [];
			var msg = '';
			var $list = $('<div/>').addClass('autocomplete-items dropdown-menu').appendTo(this.elements.autocomplete);

			if (isNonEmptyString(searchString)) {
				matches = this.getItemsForSearch(searchString, this.elements.items).slice(0, this.options.autocompleteLimit);
			} else {
				matches = this.elements.items.toArray();
			}

			$.each(matches, function (i, match) {
				match = $(match);
				var match_label = match.data('catalog-item-keywords');
				var match_url = match.data('catalog-item-url');
				var match_img = match.data('catalog-item-logo');

				// Replace the matched text with a custom span.
				var re = new RegExp("(" + searchString + ")", "gi"),
					className = that.options.highlightClass,
					template = "<span class='" + className + "'>$1</span>",
					label = match_label.replace(re, template),
					$item = $('<a/>').addClass('dropdown-item').attr("href", match_url).html(label).appendTo($list);

				if (that.options.thumbnail) {
					$('<img/>').attr('src', match_img).addClass('dropdown-item-image ml-2 mr-3').prependTo($item);
				}
			});

			if (matches.length > 0) {
				msg = 'Show all results for "' + searchString + '"';
			} else {
				msg = 'We couldn\'t find any results for "' + searchString + '"';
			}

			$('<div/>').addClass('dropdown-divider my-1').appendTo($list);
			$('<a/>').addClass('dropdown-item dropdown-summary').html(msg).appendTo($list);

			this.elements.autocomplete.find('.dropdown-summary').on('click', function () {
				that.removeFilter();
				that.autocompleteClose();
				that.applyUrlParam('search', searchString);
				that.applySearch(searchString, that.elements.items);
				that.applySearchResultTitle(searchString);
				that.handleMatches(that.elements.filters, 'catalog-filter', 'category');
				that.handleMatches(that.elements.searchCheckbox, 'catalog-item-filter', 'integration');
				that.scrollTo(that.elements.wrapper);
			});
		},

		addActive: function (items) {
			if (!items) return;
			this.removeActive(items);

			if (currentFocus >= items.length) {
				currentFocus = 0;
			}
			if (currentFocus < 0) {
				currentFocus = (items.length - 1);
			}
			$(".dropdown-item:eq( " + currentFocus + " )").addClass('active');
		},

		removeActive: function (item) {
			$.each(item, function (i) {
				$(".dropdown-item:eq( " + i + " )").removeClass('active');
			});
		},

		autocompleteClose: function () {
			var $list = $('.autocomplete-items');
			$list.remove();
		},

		applySearchResultTitle: function (searchString) {
			if ($('.result-title-phrase').length == 0) {
				$('<span/>').css('font-weight', 700).addClass('result-title-phrase').appendTo(this.elements.resultTitle);
			}
			$('.result-title-phrase').text('"' + searchString + '"')

			this.elements.resultTitle.show();
		},

		applySearch: function (searchString, items) {
			var matches = [];

			// get the search matches
			if (isNonEmptyString(searchString)) {
				matches = this.getItemsForSearch(searchString, items);
			} else {
				matches = this.elements.items.toArray();
			}

			currentMatches = $(matches);

			// if no matches at all, show no matches text
			this.searchMatchesHandler(matches);

			// show all matches
			$.each(matches, function (i, match) {
				match = $(match);
				match.show();
			});

			// hide non-matches
			items.not(function (i, item) {
				return matches.indexOf(item) >= 0;
			}).each(function (i, nonmatch) {
				nonmatch = $(nonmatch);
				nonmatch.hide();
			});
		},

		getItemsForSearch: function (searchString, items) {
			searchString = searchString.toLowerCase();
			var matchedItems = [];

			items.each(function (i, item) {
				if ($(item).find('*[data-catalog-item-checkbox]:checked').length > 0) {
					// always match selected items
					matchedItems.push(item);
				} else {
					// match items by keywords
					var keywords = $(item).data('catalog-item-keywords');
					if (isNonEmptyString(keywords)) {
						keywords = keywords.toLowerCase();
						if (keywords.indexOf(searchString) >= 0) {
							matchedItems.push(item);
						}
					}
				}
			});
			return matchedItems;
		}
	};

	// lightweight plugin wrapper around the constructor
	$.fn[pluginName] = function (options) {
		return this.each(function () {
			if (!$.data(this, "plugin_" + pluginName)) {
				$.data(this, "plugin_" + pluginName, new Catalog(this, options));
			}
		});
	};

})(jQuery, window, document);
